import React, { useEffect, useState } from "react";
import { useFirebase } from "../../Firebase/FirebaseContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: "center",
    width: "40%",
    margin: 50,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    backgroundColor: "rgba(255,255,255,0.5)",
    boxShadow: "0 5px 10px rgba(100, 100, 100, 0.5)",
    transition: "450ms",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
    },
  },
}));
export default function Home() {
  const { firebase } = useFirebase();
  const history = useHistory();
  const classes = useStyles();
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const q = query(
          collection(firebase.db, "rooms"),
          where("laboratoryId", "==", sessionStorage.getItem("currentLab"))
        );
        const arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          arr.push({ room: doc.data(), id: doc.id });
        });
        arr.sort((a, b) => a.room.name.localeCompare(b.room.name)); // Tri par nom croissant
        setRooms(arr);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Grid container justifyContent="space-around" wrap="wrap">
      {rooms.map((data, i) => (
        <Card
          key={`uniqueKey-${i}`}
          className={classes.card}
          onClick={() => {
            history.push("/waitingScreen", { data: data });
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {data.room.name}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}
