import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useFirebase } from "../../Firebase/FirebaseContext";
import { Grid, TextField } from "@material-ui/core"; // Import TextField
import AppointmentCard from "../Elements/AppointmentCard";
import { subDays } from "date-fns";

export default function LastAppointments() {
  const { firebase } = useFirebase();
  const [appointments, setAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const EightDaysAgo = subDays(new Date(), 8);

  useEffect(() => {
    (async () => {
      try {
        const appointmentsRefs = collection(firebase.db, "appointements");
        const q = query(
          appointmentsRefs,
          where("laboratoryId", "==", sessionStorage.getItem("currentLab")),
          where("ended_at", ">=", Timestamp.fromDate(EightDaysAgo)),
          orderBy("ended_at", "desc")
        );
        const qSnapshot = await getDocs(q);
        let temp = [];
        qSnapshot.forEach((appointmentDoc) => {
          temp.push({ ...appointmentDoc.data(), id: appointmentDoc.id });
        });
        setAppointments(temp);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  // Function to filter appointments based on search query
  const filteredAppointments = appointments.filter((appointment) => {
    const fullName =
      `${appointment.name} ${appointment.lastName}`.toLowerCase();
    const sanitizedQuery = searchQuery.trim().toLowerCase();

    // Check if the fullName contains the search query
    return fullName.includes(sanitizedQuery);
  });

  return (
    <div>
      {/* Add the search input field */}
      <div
        style={{
          marginBottom: 20,
          marginTop: 10,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <TextField
          label="Recherchez un patient par son nom ou prénom"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <Grid container style={{ justifyContent: "space-around", padding: 20 }}>
        {filteredAppointments.map((appointment, i) => {
          return (
            <AppointmentCard
              appointment={appointment}
              key={`appointment-uid-${i}`}
            />
          );
        })}
      </Grid>
    </div>
  );
}
