import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import FirebaseContextProvider from "./Firebase/FirebaseContext";
import Routes from "./Routes";
import { theme } from "./Theme";
import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";
function App() {
  return (
    <FirebaseContextProvider>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
          <ThemeProvider theme={theme}>
            <Routes></Routes>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </FirebaseContextProvider>
  );
}

export default App;
