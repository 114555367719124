import axios from "axios";
export const APIURL = "https://prod-f7nwx62ufq-ew.a.run.app/";
//export const APIURL = "https://back-lybo-admin.herokuapp.com/";
//export const APIURL = " http://localhost:8000/";

export const axiosInstance = axios.create({
  baseURL: APIURL,
  timeout: 8000,
  responseType: "json",
});

export default async function ExpressAPI(
  method = "GET",
  url = "/",
  data = [],
  responseType = "json"
) {
  try {
    return await axios({
      method: method,
      url: APIURL + url,
      data: { data },
      headers: {
        authorization: `Bearer ${sessionStorage.token}`,
        "Access-Control-Allow-Origin": "*",
      },
      responseType: responseType,
    });
  } catch (e) {
    if (e.response.status === 401) {
      alert("Veuillez vous reconnecter");
      sessionStorage.clear();
      window.location.reload();
    }
  }
}
