import {
  Button,
  Divider,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import CustomButton from "../../Buttons/CustomButton";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { CloudUpload } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { set } from "date-fns";

export default function UploadVideo() {
  const history = useHistory();
  const { firebase } = useFirebase();
  const classes = useStyles();
  const selectFile = useRef(null);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const fileSelectionClick = () => {
    selectFile.current.click();
  };
  const [upload, setUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sex, setSex] = useState("");
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const submit = () => {
    if (file && fileName && minAge && maxAge) {
      setUpload(true);
      const storage = getStorage();
      const trimedName = file.name.replace(/\s+/g, "");
      const url = `videos/${trimedName}`;
      const data = {
        title: fileName,
        path: url,
        sex: sex,
        minAge: parseInt(minAge),
        maxAge: parseInt(maxAge),
        createdAt: new Date(),
        laboratoryId: sessionStorage.currentLab,
      };
      const videoRef = ref(storage, url);
      const uploadTask = uploadBytesResumable(videoRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(Math.trunc(progress));
        },
        (e) => {
          console.log(e);
        },
        () => {
          addDoc(
            collection(
              firebase.db,
              "laboratories/" + sessionStorage.currentLab + "/videos"
            ),
            data
          )
            .then(() => {
              addDoc(collection(firebase.db, "videos"), {
                title: fileName,
                path: url,
                createdAt: new Date(),
              })
                .then(() => {
                  setUpload(false);
                  enqueueSnackbar("La vidéo a bien été uploadée", {
                    variant: "success",
                  });
                  history.push("/home");
                })
                .catch((e) => console.log(e));
            })
            .catch((e) => console.log(e));
        }
      );
    }
  };

  return (
    <Paper className={classes.card}>
      <Typography
        variant="h3"
        color="primary"
        style={{ display: "flex", alignItems: "center" }}
      >
        Ajouter une vidéo
      </Typography>
      <Divider style={{ margin: "20px 0" }} />
      <TextField
        label="Nom de la video"
        fullWidth
        onChange={(e) => setFileName(e.target.value)}
        style={{ marginBlock: 10 }}
        variant="outlined"
      />
      <div style={{ paddingBlock: 10 }}>
        <InputLabel id="select-sex-label">Sex</InputLabel>
        <Select
          fullWidth
          labelId="select-sex-label"
          id="select-sex"
          value={sex}
          onChange={(e) => setSex(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="h">Homme</MenuItem>
          <MenuItem value="f">Femme</MenuItem>
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBlock: 10,
        }}
      >
        <TextField
          label="Age min"
          onChange={(e) => setMinAge(e.target.value)}
          style={{ marginBottom: 20 }}
          variant="outlined"
        />
        <TextField
          label="Age max"
          onChange={(e) => setMaxAge(e.target.value)}
          style={{ marginBottom: 20 }}
          variant="outlined"
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={fileSelectionClick}
        style={{ margin: 5 }}
      >
        {file ? (
          file.name
        ) : (
          <>
            <CloudUpload style={{ marginRight: 5 }} />
            Ajouter un fichier
          </>
        )}
        <input
          type="file"
          hidden
          accept="video/mp4, video/mov"
          ref={selectFile}
          onChange={(e) => {
            //maximum 35 mb
            if (e.target.files[0].size > 35000000) {
              enqueueSnackbar("Fichier trop lourd, maximum 35mb.", {
                variant: "error",
              });
              return;
            }
            setFile(e.target.files[0]);
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </Button>
      {upload ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : (
        <CustomButton title="Confirmer" onClick={submit} />
      )}
      {progress === 100 && (
        <Typography variant="h5" color="primary">
          Succès de l'upload
        </Typography>
      )}
    </Paper>
  );
}
const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: "inherit",
    color: theme.palette.primary.main,
  },
  card: {
    margin: "50px auto",
    width: 500,
    padding: 50,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
  },
}));
