import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../Firebase/FirebaseContext";
import { AccountCircle, MoreVert } from "@material-ui/icons";
import { signOut } from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LeftMenu from "./LeftMenu";
import MenuIcon from "@material-ui/icons/Menu";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useEffect } from "react";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.light,
    "&:hover": {
      cursor: "pointer",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    color: "white", // Couleur du texte du sélecteur
    "&:before": {
      borderColor: "white", // Couleur de la bordure avant la sélection
    },
    "&:after": {
      borderColor: "white", // Couleur de la bordure après la sélection
    },
    "& .MuiSelect-select": {
      color: "white", // Couleur du texte du sélecteur
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white", // Couleur de la bordure avant la sélection
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white", // Couleur de la bordure après la sélection
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Header() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [salles, setSalles] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [selectedSalle, setSelectedSalle] = useState(""); // État pour la salle sélectionnée

  useEffect(() => {
    const q = query(
      collection(firebase.db, "laboratories"),
      where("structure", "==", sessionStorage.getItem("structure"))
    );

    // Exécutez la requête et récupérez les résultats
    getDocs(q)
      .then((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((doc) => {
          temp.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setSalles(temp);
        if (temp.length > 0) {
          let current = sessionStorage.getItem("currentLab");
          if (current) {
            setSelectedSalle(current);
          } else {
            setSelectedSalle(temp[0].id);
            sessionStorage.setItem("currentLab", temp[0].id);
          }
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des documents :", error);
      });
  }, []);

  const handleSalleChange = (event) => {
    setSelectedSalle(event.target.value);
    sessionStorage.setItem("currentLab", event.target.value);
    window.location.reload();
    // Vous pouvez effectuer des actions supplémentaires ici en fonction de la salle sélectionnée
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = () => {
    signOut(firebase.auth)
      .then(() => {
        sessionStorage.clear();
        history.push("/login");
        console.log("Déconnexion du compte");
      })
      .catch((e) => {
        console.log("Erreur lors de la déconnexion");
      });
  };

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          onAuthStateChanged(getAuth(), (user) => {
            handleMenuClose();
            history.push("/userProfile", { user: user.email });
          });
        }}
      >
        Mon compte
      </MenuItem>
      <MenuItem onClick={logout}>Déconnexion</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setIsLeftMenuOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Button className={classes.title}>
            <Typography
              variant="h6"
              noWrap
              onClick={() => history.push("/home")}
            >
              LYBO
            </Typography>
          </Button>
          <div className={classes.grow} />
          <FormControl className={classes.formControl}>
            <Select
              value={selectedSalle}
              onChange={handleSalleChange}
              inputProps={{
                name: "salle",
                id: "salle-select",
              }}
              className={classes.formControl}
              variant="standard"
            >
              {salles.map((salle) => (
                <MenuItem key={salle.id} value={salle.id}>
                  {salle.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <LeftMenu open={isLeftMenuOpen} switchMenu={setIsLeftMenuOpen}></LeftMenu>
    </div>
  );
}
