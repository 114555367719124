import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import Timer from "../../Elements/Timer";
import Legend from "../../Elements/Legend";
const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: "center",
    width: "30%",
    margin: 20,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    backgroundColor: "#003171",
    boxShadow: "0 5px 10px rgba(100, 100, 100, 0.5)",
    transition: "450ms",
    color: "white",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
    },
  },
  hostessOk: {
    backgroundColor: "#454545",
    color: "white",
  },
  technicianInCharge: {
    backgroundColor: "#D35400",
    color: "white",
  },
}));

export default function RoomsMonitoring() {
  const [rooms, setRooms] = useState([]);
  const { firebase } = useFirebase();
  const classes = useStyles();
  const history = useHistory();

  // ...
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(firebase.db, "rooms"),
        where("laboratoryId", "==", sessionStorage.getItem("currentLab"))
      ),
      (querySnapshot) => {
        const tempRooms = [];
        querySnapshot.forEach((doc) => {
          tempRooms.push({ room: doc.data(), id: doc.id });
        });
        tempRooms.sort((a, b) => a.room.name.localeCompare(b.room.name)); // Tri par nom croissant
        console.log(tempRooms);
        setRooms(tempRooms);
      }
    );

    // Nettoyer l'écouteur lors du démontage
    return () => unsubscribe();
  }, []);

  // Afficher les cartes triées par nom croissant
  return (
    <>
      <Typography
        variant="h2"
        color="primary"
        align="center"
        style={{ marginTop: 50 }}
      >
        Suivi des salles
      </Typography>
      <Legend />
      <Grid container justifyContent="space-around" wrap="wrap">
        {rooms.map((data, i) => (
          <Card
            className={
              data?.room?.appointementId
                ? classes.card + " " + classes.hostessOk
                : classes.card
            }
            key={`uniqueKey-${i}`}
            onClick={() => {
              history.push("/roomHistory", { data: data });
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {data?.room?.name}{" "}
                {data?.room?.appointementId ? (
                  <>
                    <p style={{ fontSize: "16px" }}>Occupé par</p>
                    <Timer roomId={data?.id} />
                  </>
                ) : (
                  "(Libre)"
                )}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </>
  );
}
