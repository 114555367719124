import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  CssBaseline,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import { useFirebase } from "../../Firebase/FirebaseContext";
import {
  ref,
  query,
  where,
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  writeBatch,
  doc,
} from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  // ... Vos styles ici ...
}));

export default function UpdateOldDocuments() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  /*
  const updateOldDocuments = async () => {
    setLoading(true);
    try {
      const db = getFirestore();
      const appointmentsRef = collection(db, "appointements");
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

      const q = query(appointmentsRef, where("started_at", "<", oneYearAgo)); // Limite à 10 documents

      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.docs.length);
      const updatePromises = querySnapshot.docs.map(async (doc) => {
        if (doc.data().lastName === "") return () => {};
        return updateDoc(doc.ref, {
          name: "",
          lastName: "",
        });
      });

      // await Promise.all(updatePromises);
      // alert("Les documents de plus d'un an ont été mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour des documents : ", error);
      alert("Une erreur est survenue lors de la mise à jour des documents.");
    } finally {
      setLoading(false);
    }
  };*/

  async function anonymizeAllAppointments() {
    const db = getFirestore();
    const appointementsCollection = collection(db, "appointements");
    const querySnapshot = await getDocs(appointementsCollection);

    const batchSize = 500; // Taille maximale d'un batch dans Firebase
    let batch = writeBatch(db);
    let count = 0;

    for (const docSnapshot of querySnapshot.docs) {
      batch.update(docSnapshot.ref, {
        name: "",
        lastName: "",
        anonyme: true,
      });
      count++;

      if (count === batchSize) {
        await batch.commit();
        batch = writeBatch(db);
        count = 0;
      }
    }

    // Commitez les opérations restantes
    if (count > 0) {
      await batch.commit();
    }

    console.log(
      "Tous les documents ont été anonymisés et le flag 'anonyme' a été ajouté."
    );
  }

  async function anonymizeSingleAppointment() {
    const db = getFirestore();
    const docRef = doc(db, "appointements", "037GYu53v1R8uiJpdrSD");
    try {
      await updateDoc(docRef, {
        name: "",
        lastName: "",
        anonyme: true,
      });
      console.log("Le document a été anonymisé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du document : ", error);
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Mise à jour des documents anciens
        </Typography>
        <Button
          variant="contained"
          color="primary"
          //  onClick={updateOldDocuments}
          disabled={loading}
        >
          Mettre à jour les documents
        </Button>

        <Button
          variant="contained"
          color="primary"
          //  onClick={updateOldDocuments}
          onClick={anonymizeAllAppointments}
        >
          Anonymiser les utilisateurs en BDD
        </Button>
      </Paper>
    </Grid>
  );
}
