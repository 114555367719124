import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Components/Pages/Login";
import WaitingScreen from "./Components/Pages/WaitingScreen";
import AppContainer from "./Components/Containers/AppContainer";
import Home from "./Components/Pages/Home";
import Profile from "./Components/Pages/Profile";
import LastAppointments from "./Components/Pages/LastAppointments";
import RoomsMonitoring from "./Components/Pages/Room/RoomsMonitoring";
import RoomHistory from "./Components/Pages/Room/RoomHistory";
import Statistics from "./Components/Pages/Statistics";
import UploadVideo from "./Components/Pages/Video/UploadVideo";
import ManagementVideo from "./Components/Pages/Video/ManagementVideo";
import AllVideo from "./Components/Pages/Video/AllVideo";
import EditVideo from "./Components/Pages/Video/EditVideo";
import AttribVideo from "./Components/Pages/Video/AttribVideo";
import Signup from "./Components/Pages/CreateUser";
import UserManagement from "./Components/Pages/UserManagement";
import StatisticsBeta from "./Components/Pages/StatisticsBeta";
import DeleteOldFolders from "./Components/Pages/DeleteOldFolders";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        {!sessionStorage.laboratory && <Redirect push to="/login" />}
        <Route path="/waitingScreen">
          <WaitingScreen />
        </Route>
        <AppContainer>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/roomsMonitoring">
            <RoomsMonitoring />
          </Route>
          <Route path="/roomHistory">
            <RoomHistory />
          </Route>
          <Route path="/lastAppointments">
            <LastAppointments />
          </Route>
          <Route path="/statistics">
            <Statistics />
          </Route>
          <Route path="/uploadVideo">
            <UploadVideo />
          </Route>
          <Route path="/managementVideo">
            <ManagementVideo />
          </Route>
          <Route path="/editVideo">
            <EditVideo />
          </Route>
          <Route path="/userProfile">
            <Profile />
          </Route>
          <Route path="/allvideo">
            <AllVideo />
          </Route>
          <Route path="/attribVideo">
            <AttribVideo />
          </Route>
          <Route path="/createuser">
            <Signup />
          </Route>
          <Route path="/usersmanagment">
            <UserManagement />
          </Route>
          <Route path="/statisticsBeta">
            <StatisticsBeta />
          </Route>
          <Route path="/delete_old">
            <DeleteOldFolders />
          </Route>
        </AppContainer>
      </Switch>
    </Router>
  );
}
