import {
  Button,
  Divider,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import CustomButton from "../../Buttons/CustomButton";
const useStyles = makeStyles((theme) => ({
  card: {
    margin: "50px auto",
    width: 500,
    padding: 50,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
  },
}));

export default function EditVideo() {
  const classes = useStyles();
  const history = useHistory();
  const { firebase } = useFirebase();
  const location = useLocation();
  const { id } = location.state;
  const selectFile = useRef(null);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const fileSelectionClick = () => {
    selectFile.current.click();
  };
  const [upload, setUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sex, setSex] = useState("");
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [currentFile, setCurrentFile] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const videoRef = doc(
        firebase.db,
        `laboratories/` + sessionStorage.currentLab + `/videos/${id}`
      );
      const videoDoc = await getDoc(videoRef);
      console.log(videoDoc.data());
      setFileName(videoDoc.data().title);
      setSex(videoDoc.data().sex);
      setMinAge(videoDoc.data().minAge);
      setMaxAge(videoDoc.data().maxAge);
      setFile({ name: videoDoc.data().path });
      setCurrentFile(videoDoc.data().path);
    })();
  }, []);

  const submit = async () => {
    if (file && fileName && minAge && maxAge) {
      try {
        setUpload(true);
        if (currentFile === file.name) {
          console.log("same file");
          const data = {
            title: fileName,
            sex: sex,
            minAge: parseInt(minAge),
            maxAge: parseInt(maxAge),
            updatedAt: new Date(),
          };
          const docRef = doc(
            firebase.db,
            `laboratories/` + sessionStorage.currentLab + `/videos/${id}`
          );
          await updateDoc(docRef, data);
          history.goBack();
          console.log("updated");
          enqueueSnackbar("La vidéo a bien été modifiée", {
            variant: "success",
          });
          return;
        }
        const storage = getStorage();
        const trimedName = file.name.replace(/\s+/g, "");
        const url = `videos/${trimedName}`;
        const oldVideoRef = ref(storage, currentFile);
        deleteObject(oldVideoRef);
        const data = {
          title: fileName,
          path: url,
          sex: sex,
          minAge: parseInt(minAge),
          maxAge: parseInt(maxAge),
          updatedAt: new Date(),
        };
        const videoRef = ref(storage, url);
        const uploadTask = uploadBytesResumable(videoRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(Math.trunc(progress));
          },
          (e) => {
            console.log(e);
          },
          () => {
            const docRef = doc(
              firebase.db,
              `laboratories/` + sessionStorage.currentLab + `/videos/${id}`
            );
            updateDoc(docRef, data)
              .then(() => {
                setUpload(false);
                console.log("updated with new file");
                enqueueSnackbar("La vidéo a bien été modifiée", {
                  variant: "success",
                });
                history.goBack();
              })
              .catch((e) => console.log(e));
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Paper className={classes.card}>
      {currentFile && (
        <>
          <Typography
            variant="h3"
            color="primary"
            style={{ display: "flex", alignItems: "center" }}
          >
            Modifier une video
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          <TextField
            label="Nom de la video"
            fullWidth
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            style={{ marginBlock: 10 }}
            variant="outlined"
          />
          <div style={{ paddingBlock: 10 }}>
            <InputLabel id="select-sex-label">Sex</InputLabel>
            <Select
              fullWidth
              labelId="select-sex-label"
              id="select-sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="h">Homme</MenuItem>
              <MenuItem value="f">Femme</MenuItem>
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: 10,
            }}
          >
            <TextField
              label="Age min"
              value={minAge}
              variant="outlined"
              onChange={(e) => setMinAge(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            <TextField
              label="Age max"
              value={maxAge}
              onChange={(e) => setMaxAge(e.target.value)}
              style={{ marginBottom: 20 }}
              variant="outlined"
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={fileSelectionClick}
            style={{ margin: 5 }}
          >
            {file ? (
              file.name
            ) : (
              <>
                <CloudUpload style={{ marginRight: 5 }} />
                Ajouter un fichier
              </>
            )}
            <input
              type="file"
              hidden
              accept="video/mp4, video/mov"
              ref={selectFile}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </Button>
          {upload ? (
            <LinearProgress variant="determinate" value={progress} />
          ) : (
            <CustomButton title="Confirmer" onClick={submit} />
          )}
          {progress === 100 && (
            <Typography variant="h5" color="primary">
              Succès de l'upload
            </Typography>
          )}
        </>
      )}
    </Paper>
  );
}
